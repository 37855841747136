body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root, .App {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.App {
  font-family: Arial, sans-serif;
  text-align: center;
  background-color: #333;
}

input[type="text"] {
  width: 80%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

input[type="range"] {
  width: 200px; /* Adjust the width to make it consistent across browsers */
}
